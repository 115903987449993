import React, { useEffect, useRef, useState, useMemo } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
import { useLocation } from "react-router-dom";

const Portfolio = () => {
  const isotope = useRef();
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    PROJECTS: "Projects",
    PERSONAL: "Personal Projects",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = useMemo(
    () => [
      {
        title: "IDentify",
        type: types.DOCUMENT,
        document: {
          projectInfo:
            "My best project to date. Our system allows businesses to provide customers with QR codes. The customer can then take or upload an image of their drivers license to be scanned and added to the system, no paper required. We finished 2nd at the annual UOW SCIT Tradeshow with this project.",
          client: "CSIT321 - Dr John Le",
          technologies:
            "TypeScript, Angular, Java Spring Boot, Python, YOLACT, OpenCV, EasyOCR",
          features:
            "Machine learning 0 shot classification, real time API backend integrated with fronted. User form automation using DocXTemplater.",
          date: "2023",
          urls: [
            {
              title: "Github",
              name: "Github Repository",
              link: "https://github.com/damonDevelops/IDentify",
            },
          ],
          sliderImages: [
            "images/projects/identify/2.jpg",
            "images/projects/identify/3.jpg",
            "images/projects/identify/5.jpg",
            "images/projects/identify/7.jpg",
            "images/projects/identify/10.jpg",
            "images/projects/identify/12.jpg",
            "images/projects/identify/13.jpg",
            "images/projects/identify/16.jpg",
          ],
        },
        thumbImage: "images/projects/identify/15.jpg",
        categories: [filters.PROJECTS],
      },
      {
        title: "Tradie Connect",
        type: types.DOCUMENT,
        document: {
          projectInfo:
            "Tradie Connect is a three user based system connecting tradies to customer jobs, with an admin that can view statistics and generate reports. TradieConnect allows a customer to broadcast a job which service providers can apply for and complete, and the customer can review the Tradie also.",
          client: "CSIT314",
          technologies: "React, JavaScript, Java Spring Boot, Python",
          features:
            "Three user based system, three separate interfaces. User Authentication. User Routing. Realtime API updates. PDF Report Generation",
          date: "2023",
          urls: [
            {
              tite: "GitHub",
              name: "Tradie Connect Github",
              link: "https://github.com/damonDevelops/Tradie-Connect",
            },
          ],
          sliderImages: [
            "images/projects//tradieConnect/1.jpg",
            "images/projects//tradieConnect/2.jpg",
            "images/projects//tradieConnect/3.jpg",
            "images/projects//tradieConnect/8.jpg",
            "images/projects//tradieConnect/12.jpg",
            "images/projects//tradieConnect/7.jpg",
          ],
        },
        thumbImage: "images/projects//tradieConnect/17.jpg",
        categories: [filters.PROJECTS],
      },
      {
        title: "Mercury",
        type: types.DOCUMENT,
        document: {
          projectInfo:
            "A microservice based web app for a cycling company. Allows the user to create, buy and store parts and update inventory numbers all in separate microservices. Uses Apache Kafka for instant realtime processing.",
          client: "CSIT318",
          technologies: "React, JavaScript, Apache Kafka, Java Spring Boot",
          features:
            "Realtime data streaming using Kafka, realtime data display using React, admin panel for software overview.",
          date: "July 16, 2019",
          urls: [
            {
              title: "GitHub",
              name: "Mercury Github",
              link: "https://github.com/jjcoop/mercu",
            },
          ],
          sliderImages: [
            "images/projects/mercury/1.jpg",
            "images/projects/mercury/3.jpg",
            "images/projects/mercury/4.jpg",
            "images/projects/mercury/5.jpg",
          ],
        },
        thumbImage: "images/projects/mercury/2.jpg",
        categories: [filters.PROJECTS],
      },
      {
        title: "Fly Dream Air",
        type: types.DOCUMENT,
        document: {
          projectInfo:
            "A simple website to mock an airline booking service. Allows users to book seats on single or return flights, add extras and manage their bookings. A project built shortly after learning to code (really shows how far I've come as a developer)!",
          client: "CSIT214",
          technologies: "HTML, JavaScript, CSS",
          features: "No frontend framework, localStorage, text file download",
          date: "2021",
          urls: [
            {
              title: "GitHub",
              name: "FlyDreamAir Github",
              link: "https://github.com/damonDevelops/FlyDreamAir",
            },
          ],
          sliderImages: [
            "images/projects/flyDreamAir/1.jpg",
            "images/projects/flyDreamAir/3.jpg",
            "images/projects/flyDreamAir/4.jpg",
            "images/projects/flyDreamAir/5.jpg",
            "images/projects/flyDreamAir/6.jpg",
            "images/projects/flyDreamAir/7.jpg",
          ],
        },
        thumbImage: "images/projects/flyDreamAir/2.jpg",
        categories: [filters.PROJECTS],
      },
      {
        title: "Business Analyzer",
        type: types.DOCUMENT,
        document: {
          projectInfo:
            "A deployed project which uses a Google Places API key to rapidly increase the efficiency of finding potential new clients based on weighted criteria. The tool is for both myself and others, I built it for my web development business to source suitable clients.",
          client: "Myself!",
          technologies: "Python, Streamlit, Pandas, TextBlob",
          features:
            "Configurable user input such as location, industry, results and grading threshold. Customisable grading weights and CSV columns. Real time data display.",
          date: "Sep 2024",
          urls: [
            {
              title: "Business Analyzer Webapp",
              name: "Business Analyzer",
              link: "https://business-analyzer.streamlit.app/",
            },
          ],
          sliderImages: ["images/projects/businessAnalyzer/2.png"],
        },
        thumbImage: "images/projects/businessAnalyzer/3.png",
        categories: [filters.PERSONAL],
      },
      {
        title: "Indeed Scraper Streamlit Application",
        type: types.DOCUMENT,
        document: {
          projectInfo:
            "A Streamlit Python project I built to allow me to scrape job listings from indeed based on parameters. This allows me to get a much quicker overview of jobs so I can pick which ones I like.",
          client: "Myself!",
          technologies: "Python, Streamlit, Selenium, BeautifulSoup, Pandas",
          features:
            "Configurable user input such as location, job type, number of jobs. CSV exporting",
          date: "Oct 2024",
          urls: [
            {
              title: "Indeed Scraper GitHub",
              name: "Indeed Scraper",
              link: "https://github.com/damonDevelops/Streamlit-Indeed-Scraper",
            },
          ],
          sliderImages: ["images/projects/indeedScraper/2.png"],
        },
        thumbImage: "images/projects/indeedScraper/1.png",
        categories: [filters.PERSONAL],
      },
      {
        title: "AFL Scores",
        type: types.DOCUMENT,
        document: {
          projectInfo:
            "A personal project I developed to view completed, live and future AFL games on my Garmin watch. I found it frustrating that no one had developed this app so I decided to publish one myself!",
          client: "Myself!",
          technologies: "Monkey C",
          features:
            "Live API calls, up to date information, intricate error handling, device scaling settings",
          date: "2024",
          urls: [
            {
              title: "Garmin Application Page",
              name: "AFL Scores",
              link: "https://apps.garmin.com/en-US/apps/fdc97ea4-f26c-4170-9f0a-68bb443fc090",
            },
          ],
          sliderImages: [
            "images/projects/aflScores/GamesMenu.png",
            "images/projects/aflScores/GameView.png",
            "images/projects/aflScores/MainMenu.png",
            "images/projects/aflScores/UpcomingGameView.png",
            "images/projects/aflScores/UpcomingMenu.png",
          ],
        },
        thumbImage: "images/projects/aflScores/GameView.png",
        categories: [filters.PERSONAL],
      },
      {
        title: "Secret Santa Message Service",
        type: types.DOCUMENT,
        document: {
          projectInfo:
            "A personal project I made to do our family secret santa every year. I was sick of someone having to organise it and them know who everyone had. My system uses Twilio so everyone gets sent a message with their secret santa.",
          client: "Myself!",
          technologies: "Python, PyQt5, Twilio",
          features:
            "Couples or individuals, easy to use desktop interface, Twilio messaging, CSV import for easy loading of more people or couples.",
          date: "2022",
          urls: [
            {
              title: "GitHub",
              name: "Secret Santa Github",
              link: "https://github.com/damonDevelops/python_projects",
            },
          ],
          sliderImages: ["images/projects/secretSanta/2.jpg"],
        },
        thumbImage: "images/projects/secretSanta/1.jpg",
        categories: [filters.PERSONAL],
      },
      {
        title: "Google Calendar Weekly Reminder Email",
        type: types.DOCUMENT,
        document: {
          projectInfo:
            "I can be forgetful when it comes to actually viewing my events on Google Calendar for each week or even the following day. I wrote a script to send myself an email each week with a summary of my events, as well as my partner's events.",
          client: "Myself!",
          technologies:
            "Python, Google Cloud Functions, Google Cloud Scheduler, Email",
          features:
            "Integrates multiple calendar's, automatic email delivery, Google IAM permissions and authentication",
          date: "2024",
          urls: [
            {
              title: "GitHub",
              name: "Google Calendar Reminder Github",
              link: "https://github.com/damonDevelops/WeeklyGoogleCalendarEmailAutomation",
            },
          ],
        },
        thumbImage: "images/projects/weeklyCalendar/1.png",
        categories: [filters.PERSONAL],
      },
      {
        title: "Nightly Discord Google Calendar Reminder",
        type: types.DOCUMENT,
        document: {
          projectInfo:
            "Every night at 7PM, I run a script hosted and scheduled on Google Cloud Platform which sends a discord message to a dedicated discord channel. This means I never even have to open the Google Calendar app. I probably could have saved time by just using the calendar like a normal person but coding is fun.",
          client: "Myself!",
          technologies:
            "Python, Google Cloud Functions, Google Cloud Scheduler, Discord Python API",
          features:
            "Integrates multiple calendar's, Scheduled Discord Delivery, Google IAM permissions and authentication",
          date: "2024",
          urls: [
            {
              title: "GitHub",
              name: "Discord Calendar Reminder Github",
              link: "https://github.com/damonDevelops/DiscordCalenderReminderWebhook",
            },
          ],
        },
        thumbImage: "images/projects/discordCalendar/1.png",
        categories: [filters.PERSONAL],
      },
      {
        title: "AFL Quick View",
        type: types.DOCUMENT,
        document: {
          projectInfo:
            "A Chrome Extension I developed to quickly check live afl match scores, fixtures as well as the live ladder for those who don't want to be caught on the website at work 👀!",
          client: "Myself!",
          technologies: "Javascript, CSS, HTML5",
          features:
            "External API integration including real time SSE updates, responsive light and dark mode, preview fake live match",
          date: "2024",
          urls: [
            {
              title: "Github",
              name: "AFL Quick View Github (open source)",
              link: "https://github.com/damonDevelops/AFL-Quick-View",
            },
            {
              title: "Web Store Page",
              name: "Chrome Web Store Page",
              link: "https://chromewebstore.google.com/detail/afl-quick-view/fofhoiagmffknhkpgbmppdaecmbengjg",
            },
          ],

          sliderImages: [
            "images/projects/aflQuickView/2.png",
            "images/projects/aflQuickView/1.png",
            "images/projects/aflQuickView/3.png",
          ],
        },
        thumbImage: "images/projects/aflQuickView/small-promo.png",
        categories: [filters.PERSONAL],
      },
    ],
    [filters.PROJECTS, types.DOCUMENT, filters.PERSONAL]
  );

  // Initialize Isotope
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    return () => {
      isotope.current.destroy();
    };
  }, [isRtl]);

  useEffect(() => {
    if (imagesLoaded > 0) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: "*" })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    return categories.map((category) => getKeyByValue(category)).join(" ");
  };

  const openProjectModal = (project) => {
    setSelectedProjectDetails(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProjectDetails(null);
    document.getElementById("portfolio").scrollIntoView({ behavior: "smooth" });
  };

  // Check for a project query parameter
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const projectTitle = params.get("project");
    if (projectTitle) {
      const project = projectsData.find(
        (p) => p.title.toLowerCase() === projectTitle.toLowerCase()
      );
      if (project) {
        openProjectModal(project);
      }
    }
  }, [location.search, projectsData]);

  return (
    <>
      <section id="portfolio" className="section">
        <div className="container">
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent projects
          </h2>
          {/* Filter Menu */}
          <ul className="portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp">
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.map((project, index) => (
                <div
                  className={
                    "col-sm-6 col-lg-4 filter-item " +
                    getFilterClasses(project.categories)
                  }
                  key={index}
                >
                  <div className="portfolio-box">
                    <div className="portfolio-img">
                      <img
                        onLoad={() => setImagesLoaded(imagesLoaded + 1)}
                        className="img-fluid d-block portfolio-image"
                        src={project.thumbImage}
                        alt={project.title}
                      />
                      <div
                        className="portfolio-overlay"
                        onClick={() => openProjectModal(project)}
                      >
                        <button className="popup-ajax stretched-link border-0 p-0 "></button>
                        <div className="portfolio-overlay-details">
                          <p className="text-primary text-8">
                            {project.type === types.DOCUMENT && (
                              <i className="fas fa-file-alt"></i>
                            )}
                            {project.type === types.IMAGE && (
                              <i className="fas fa-image"></i>
                            )}
                            {project.type === types.VIDEO && (
                              <i className="fas fa-video"></i>
                            )}
                          </p>
                          <h5 className="text-white text-5">{project.title}</h5>
                          <span className="text-light">Category</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isModalOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default Portfolio;
